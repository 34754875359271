<template>
  <div>
    <el-dialog title="题目类别"
               :visible.sync="dialogVisible"
               v-dialogDrag
               :close-on-click-modal="false"
               :append-to-body="true"
               width="400px"
               :before-close="handleClose">
      <el-row class="row_center">
        <!-- <el-cascader v-model="topicType_ids"
                     :options="topicTypeTree"
                     :show-all-levels="false"
                     style="width:100%"
                     @change="change"
                     :props="{ expandTrigger: 'hover',
                   checkStrictly: true ,
                   multiple:true, 
                   checkStrictly:true,
                   value:'question_category_id',
                   label:'question_category_name',
                   children:'child' }"></el-cascader> -->

        <el-select v-model="topicType_ids"
                   style="width:100%"
                   placeholder="请选择"
                   @change="change"
                   multiple>
          <el-option v-for="item in topicTypeTree"
                     :key="item.question_category_id"
                     :label="item.question_category_name"
                     :value="item.question_category_id">
          </el-option>
        </el-select>
      </el-row>
      <el-row :gutter="12">
        <!-- {{tags}} -->
        <el-col :span="8"
                style="margin:10px 0;"
                v-for="(item,index) in tags"
                :key="index">
          <el-tag>{{item.question_category_name}}</el-tag>
        </el-col>
      </el-row>
      <span slot="footer"
            class="dialog-footer">
        <el-button type="primary"
                   @click="save">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      topicTypeTree: [],
      topicType_ids: [],
      dialogVisible: false,
      copyList: [],
      tags: [],
      form: {},
      arr: []
    }
  },
  methods: {
    getTopicTypeTree (subject_id) {
      this.$http({
        url: '/api/v2/public/question_category_lst',
        method: 'get',
        params: {
          subject_id
        }
      }).then(res => {
        this.topicTypeTree = res.data.list
        this.flatList(JSON.parse(JSON.stringify(res.data.list))).forEach(item => {
          this.copyList[item.question_category_id] = item
        })
        this.getRelationTopicType()
      })
    },
    getRelationTopicType () {
      this.$http({
        url: '/api/v2/knowledge/get_question_category',
        method: 'get',
        params: {
          knowledge_id: this.form.knowledge_id
        }
      }).then(res => {

        let ids = res.data.data

        this.topicType_ids = res.data.data
        this.change()
      })
    },

    save () {
      let question_category_ids = this.tags.map(item => {
        return item.question_category_id
      })
      let form = JSON.parse(JSON.stringify(this.form))
      form.question_category_ids = question_category_ids

      this.$http({
        url: '/api/v2/knowledge/relation',
        method: 'post',
        data: form
      }).then(res => {
        this.$notify({
          title: '提示',
          message: '操作成功!',
          type: 'success'
        });
        this.$parent.getTree()
        this.handleClose()
        // this.$parent.search()
      })
    },
    handleClose () {
      this.dialogVisible = false
    },
    change () {
      this.tags = this.topicType_ids.map(item => {
        console.log(item)
        return {
          question_category_name: this.copyList[item].question_category_name,
          question_category_id: item,
        }
      })
    },
    flatList (list) {
      let allList = []
      for (let i = 0, len = list.length; i < len; i++) {
        allList.push(list[i])
        if (list[i].child && list[i].child.length > 0) {
          allList = allList.concat(this.flatList(list[i].child))
        }
      }
      return allList
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__header {
  font-size: 20px;
  font-weight: bold;
  color: black;
  text-align: center;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: white;
  background: #cccccc;
  border-radius: 50%;
  padding: 4px;
}
::v-deep .el-dialog__footer {
  text-align: center;
}

::v-deep .el-dialog {
  border-radius: 15px;
}

::v-deep .el-tabs__nav-wrap::after {
  background: none;
}
.row_center {
  display: flex;
  justify-content: center;
  height: 36px;
  overflow: hidden;
}
::v-deep .el-cascader__tags {
  height: 36px;
  overflow: hidden;
  margin-top: 6px;
}
::v-deep .el-select__tags {
  height: 36px;
  // line-height: 36px;
  overflow: hidden;
  // margin-top: 4px;
}
::v-deep .el-select .el-tag {
  margin: 6px 0 2px 6px;
}
::v-deep .el-tag__close {
  display: none;
}
::v-deep .el-input__inner {
  height: 36px !important;
}
</style>